import React, { Component } from 'react'

class ZonaClienti extends Component {
    render() { 
                
    return ( 
        <div>
            <section className="internal-page-wrapper">
                    <div className="row justify-content-center text-center clienti-section">
                            <div className="col-12">
                            </div>
                    </div>
                    <div className="container"> 
                
                        <div className="row justify-content-center text-center">
                                    <div className="col-12">
                                            <div className="title-wraper large">
                                                Bine ați venit la Audiogram, acasă pentru inovație, pasiune și sunet autentic! 								
                                            </div>
                                                <div className="zona-description">
                                                    Într-un ton jucăuș și plin de căldură, îți prezentăm serviciile și produsele noastre care aduc magia sunetului în fiecare zi.								
                                                </div>
                                        
                                                <div className="zona-description">
                                                    Teste Auditive Prietenoase: Experimentează o evaluare auditivă relaxată și personalizată, în care ne concentrăm pe înțelegerea completă a universului tău auditiv.  
                                                </div> 
                                                <div className="zona-description">
                                                    La noi poți sa faci: 
                                                </div>   
                                            <ul className="zona-clienti"> 
                                                <li>
                                                    <a href="/audiograma">Audiogramă completă</a>   
                                                </li>    
                                                <li>
                                                    <a href="/timpanograma">Timpanogramă</a>  
                                                </li>    
                                                <li>
                                                    <a href="/reglaje-rem">Reglaje REM</a> 
                                                </li>    
                                                <li>
                                                    <a href="/tinnometrie">Tinnometrie</a>  
                                                </li>      
                                                <li>
                                                    <a href="/act">ACT - Threshold de Contrast Auditiv</a>   
                                                </li> 
                                            </ul> 
                                        <div className="zona-description">
                                            Adică tot ce mai bun in domeniul testării auzului								
                                        </div>
                                        <ul className="zona-clienti">
                                            <li>
                                                Aparate auditive cu suflet: Echipa noastră de experți în audiologie este aici pentru a-ți oferi consultanță personalizată și îndrumare, mereu cu un zâmbet pe buze și o atitudine prietenoasă.  Inca ceva… la noi o să poți mereu să probezi aparatele auditive înainte de a te decide ce vrei să porti.  
                                            </li>  
                                            <li>
                                                Adaptare și Îngrijire Continuă: Poți să fii liniștit știind că suntem alături de tine, oferindu-ți suport în adaptare și îngrijire continuă pentru a te asigura că experimentezi întotdeauna sunetul în toată splendoarea sa.   
                                            </li>    
                                            <li>
                                                AUZ de la A la Z: Exact așa cum ai citit. La noi găsești tot pentru auzul tău.   
                                            </li>    
                                        </ul>
                                        <div className="zona-description">
                                            La Audiogram, promitem să  îți oferim o experiență auditivă PREMIUM, plină de zâmbete și emoții, unde sunetul devine poveste și  povestea aventură. 								
                                        </div>
                                        <div className="zona-description">
                                            Audiogram e CU si DESPRE tehnologie de vârf… 								
                                        </div>
                                        <div className="zona-description">
                                            Ca de n-ar fi, nu s-ar auzi! 								
                                        </div>
                                        </div>
                                    </div>
                    </div>
            </section>
        </div>
        
        );     
    }
}
 
export default ZonaClienti;
