import React, { Component } from 'react';
import './App.css';
import ReactGA from 'react-ga';




import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Navbar from "./component/Header/Navbar"
import Main  from "./component/Main/main"
import Footer from "./component/Footer/Footer"

import Error from "./component/Pages/Error/Error"
import Blog from './component/Pages/Blog/Blog';
import Watch from './component/Pages/Watch/Watch'
import Productpage from './component/Pages/Productpage/Productpage'
import Aparate from './component/Pages/Aparate/Aparate';
import Locatii from './component/Pages/Locatii/Locatii';
import Termeni from './component/Pages/Termeni/Termeni';
import DespreNoi from './component/Pages/Desprenoi/Desprenoi';
import Ajutor from './component/Pages/Ajutor/Ajutor';
import ZonaClienti from './component/Pages/Zonaclienti/Zonaclienti';
import Audiograma from './component/Pages/Audiograma/Audiograma';
import Timpanograma from './component/Pages/Timpanograma/Timpanograma';
import ReglajeRem from './component/Pages/Reglajerem/Reglajerem';
import Tinnometrie from './component/Pages/Tinnometrie/Tinnometrie';
import Act from './component/Pages/Act/Act';
import ZonaProfesionisti from './component/Pages/Zonaprofesionisti/Zonaprofesionisti';
import Oracle from './component/Pages/Oracle/Oracle';
import Programare from './component/Pages/Programare/Programare';
import Colaboratori from './component/Pages/Colaboratori/Colaboratori';
import Centroacusticoitaliano from './component/Pages/Centroacusticoitaliano/Centroacusticoitaliano';
import Aparateauditiveploiesti from './component/Pages/Aparateauditiveploiesti/Aparateauditiveploiesti';
import ArticlePage from './component/Pages/Articlepage/Articlepage';


class App extends Component {
  componentDidMount() {
    ReactGA.initialize('G-FLXDGLS6QF');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  render(){
    return (
      <Router>
        <div>  
          <Navbar/>
            <Switch>
                <Route path="/" exact component={Main}/>
                <Route path="/colaboratori" exact component={Colaboratori}/>
                <Route path="/ajutor" exact component={Ajutor}/>
                <Route path="/aparate-auditive" exact component={Aparate}/>
                <Route path="/termeni-si-conditii" exact component={Termeni}/>
                <Route path="/zona-clienti" exact component={ZonaClienti}/>
                <Route path="/audiograma" exact component={Audiograma}/>
                <Route path="/timpanograma" exact component={Timpanograma}/>
                <Route path="/reglaje-rem" exact component={ReglajeRem}/>
                <Route path="/tinnometrie" exact component={Tinnometrie}/>
                <Route path="/zona-profesionisti" exact component={ZonaProfesionisti}/>
                <Route path="/programare" exact component={Programare}/>
                <Route path="/oracle-chamber" exact component={Oracle}/>
                <Route path="/act" exact component={Act}/>
                <Route path="/despre-noi"  component={DespreNoi}/>
                <Route path="/blog" exact component={Blog}/>
                <Route path="/watch" exact component={Watch}/>
                <Route path="/aparate/:pid" exact component={Productpage}/>
                <Route path="/blog/:id" component={ArticlePage} />
                <Route path="/locatii" component={Locatii} />
                <Route path="/centroacusticoitaliano" component={Centroacusticoitaliano} />
                <Route path="/aparateauditiveploiesti" component={Aparateauditiveploiesti} />
                <Route path="/" component={Error}/>
            </Switch>          
          <Footer/>
        </div>
      </Router>
      
    );
  }
  
}

export default App;
