import React, {Component} from 'react';
import Modal from '../../Modal/Modal';


class Ajutor extends Component{
	constructor(){
        super();
        this.state = {
            products: []
        }
    }
    componentDidMount(){
        fetch("./ajutor.json")
            .then(res => res.json())
            .then((x) => {

                this.setState((state) => {
                    return{
                        products: x.products,
                    };
                })
            })
    }

	openModal = (videoSrc) => {
        this.setState({
            showModal: true,
            videoSrc: videoSrc
        });
    };

    closeModal = () => {
        this.setState({
            showModal: false
        });
    };
    render(){
        // console.log(this.state.products)
        let order = 1;
        return (
            <div>
				<section className="internal-page-wrapper">
					<div className="container"> 
						<div className="row justify-content-center text-center">
							<div className="col-12">
								<div className="title-wraper large">
								Te ajutăm sa auzi mai bine?
								</div>
								{/* <div className="brief-description">
									The best for the brightest
								</div> */}
							</div> 
						</div>
						{
							this.state.products.map((product) => {
								let id = product.Id;
								let productPage = "/iphone/"+id;

								let order1 = 1;
								let order2 = 2;
								if(order !== 1)	{
									order1 = 2;
									order2 = 1;
									order--;
								}else{
									order++;
								}

								let productDiv =
								<div key={id} className="row justify-content-center text-center product-holder h-100 ajutor">
									<div className={`col-sm-12 col-md-6 my-auto order-${order1}`}>
										<div className ="new-alert">
											{product.Alert}
										</div>
									<div className="monthly-price inch">
												{product.PriceRange}
											</div>
										<div className="product-title">
                                        { product.Title}
										</div>
										<div className="description-wraper">
											{product.Brief}
										</div>
										
										
										<div className="links-wrapper">
											<ul>
												<li>
												<button className="button-ajutor" onClick={() => this.openModal(product.video)}>Vezi mai mult ></button>
												</li>
											</ul> 
										</div>
									</div> 
									<div  className={`col-sm-12 col-md-6 order-${order2}`}>
									<div className="prodict-image">
									<iframe
										width="560"
										height="315"
										src={product.video}
										title={product.Title}
										frameBorder="0"
										allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
										allowFullScreen
									></iframe>
									</div>
									</div> 
								</div>								
								;
								return productDiv; 
							})
						}
				
					</div>
				</section>
				  {/* Modalul */}
				  {this.state.showModal && (
                    <Modal onClose={this.closeModal}>
                        <iframe
                            width="800"
                            height="600"
                            src={this.state.videoSrc}
                            title="video"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </Modal>
                )}
			</div>
        )
    }
			
}
export default Ajutor;