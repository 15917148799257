import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Blog extends Component {
    constructor() {
        super();
        this.state = {
            articles: []
        }
    }

    componentDidMount() {
        fetch("./blog.json")
            .then(res => res.json())
            .then((x) => {
                this.setState({
                    articles: x.articles,
                });
            })
    }

    render() {
		let order = 1;
        // console.log(this.state.articles);

        return (
            <div>
                <section className="internal-page-wrapper">
                    <div className="container">

						
                        <div className="row justify-content-center text-center">
                            <div className="col-12">
                                <div className="title-wraper large">
                                    Blog
                                </div>
                            </div>
                        </div>

                        {
						    this.state.articles.map((article) => {

								let id = article.Id;


                                let order1 = 1;
                                let order2 = 2;
                                if (order !== 1) {
                                    order1 = 2;
                                    order2 = 1;
                                    order--;
                                } else {
                                    order++;
                                }

                                return (
								<div key={id} className="row justify-content-center text-center blog-holder h-100 blog">
									<div className={`col-sm-12 col-md-6 my-auto order-${order1}`}>
										<div className="description-wraper blog">
											<h2>{article.Title}</h2>
											<p>{article.Brief}</p>
										</div>
										<div className="links-wrapper">
											<ul>
												<li>
													<Link to={`/blog/${article.Id}`}>Vezi mai mult</Link>
												</li>
											</ul>
										</div>
									</div>
									<div className={`col-sm-12 col-md-6 my-auto order-${order2}`}>
										<div className="blog-image">
											<img src={article.img} alt={article.Title} />
										</div>
									</div>
								</div>
							);
						})

                        }
                    </div>
                </section>
            </div>
        )
    }
}

export default Blog;
