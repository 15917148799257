import React, { Component } from 'react'
import { Link } from 'react-router-dom';

class Timpanograma extends Component {
    render() { 
                
    return ( 
        <div>
            <section className="internal-page-wrapper">
                    <div className="container"> 
                        <div className="row justify-content-center text-center">
                                    <div className="col-12">
                                            <div className="title-wraper large">
                                                Timpanograma jucăușă:
                                            </div>
                                                <div className="zona-description">
                                                    La Audiogram, suntem dedicați evaluării și îngrijirii sănătății urechii medii, utilizând tehnici avansate și echipamente de ultimă generație.  								
                                                </div>
                                                <div className="zona-description">
                                                    Timpanograma este un test non-invaziv și nedureros, care evaluează starea timpanului și a urechii medii, oferind informații esențiale despre funcționarea sistemului auditiv. 
                                                </div> 
                                                <div className="zona-description">
                                                    Testul este realizat de către experți în audiologie, care vă vor ghida pas cu pas prin fiecare etapă a procesului, oferindu-vă explicații clare și îndrumare personalizată. 
                                                </div>
                                                <div className="zona-description">
                                                    Rezultatele precise ale timpanogramei,  ne ajută să identificăm posibilele afecțiuni ale urechii medii, precum și să recomandăm cele mai potrivite soluții pentru fiecare pacient în parte. 
                                                </div>
                                                <div className="zona-description">
                                                    Pentru o Timpanogramă  completă si precisă, puteți face o programare aici: 
                                                </div>       
                                            <h3 className="zona-description pret">Pret: 75 lei</h3>
                                        </div>
                                    </div>
                    </div>
            </section>
        </div>
        
        );     
    }
}
 
export default Timpanograma;
