import React, { Component } from "react";
import "./FirstContaint.css";
import Structure from "../Containts/Structure";

class FirstContaint extends Component {
    render() {
        return (
            <section className="first-highlight-wrapper">
				<video autoPlay muted loop id="bg-video" playsInline preload="auto">
                    <source src="https://audio-gram.ro/video/puternicul-1.mp4" type="video/mp4" />
                    {/* Adaugă surse pentru alte formate de fișiere video, dacă este necesar */}
                    Your browser does not support the video tag.
                </video>
                <div className="content">
                    <Structure
                        titleColor="white"
                        title="Inteligentul"
                        colorClass="white"
                        description="Cu inteligență artificială. Auzul perfect există."
                    />
                    {/* <div className="ipod-caption row">
                        <div className="col-sm-12 col-md-6 text-md-right">iPad Pro available starting 3.25</div>
                        <div className="col-sm-12 col-md-6 text-md-left">Magic Keyboard coming in May</div>
                    </div> */}
                </div>
            </section>
        );
    }
}

export default FirstContaint;



