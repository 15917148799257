import React, { Component } from 'react'

class ZonaProfesionisti extends Component {
    render() {
        return (
            <div>
                <section className="internal-page-wrapper">
                <div className="row justify-content-center text-center profesionisti-section">
                            <div className="col-12">
                            </div>
                </div>
                <div className="container">
                        <div className="title-wrapper large despre">
                            Colaborare pentru Succes si un auz perfect pentru clientii nostri.
                        </div>
                        <div className="zona-description">
                            Bine ați venit în zona noastră dedicată colaboratorilor și partenerilor! Suntem încântați să explorăm oportunități de colaborare strategică cu companii de prestigiu si liberalizam folosirea brandului de aparate auditive Philips pe piata din Romania. 
                        </div>
                        <div className="zona-description">
                            Cum putem lucra împreună:
                        </div>
                        <ul className="zona-profesionisti"> 
                            <li>
                                <a><strong>1. Inovație Tehnologică:</strong>Suntem distribuitorul EXCLUSIV al aparatelor auditive Philips Hearing Solutions, dar dezvoltam un intreg ecosistem in jurul Audiogram prin care putem aduce inovație și eficiență în domeniul produselor și serviciilor pe care le oferiți.</a>   
                            </li>    
                            <li>
                                <a><strong>2. Expertiză În Sector:</strong> Cu o echipă experimentată și o vastă expertiză în diverse domenii, suntem pregătiți să colaborăm cu companii, pentru a identifica și a exploata noi oportunități de creștere și dezvoltare pe piață.</a>  
                            </li>    
                            <li>
                                <a><strong>3. Promovare și Marketing:</strong> Prin parteneriate strategice si o echipa intreaga de marketing nisat, putem amplifica vizibilitatea și impactul brandului dumneavoastră. Prin intermediul platformei noastre, vă oferim oportunități de promovare eficiente și personalizate, care să vă ajute să vă atingeți obiectivele de marketing și vânzări.</a> 
                            </li> 
                            <li>
                                <a><strong>4. Suport și Asistență: </strong> Oferim suport dedicat și asistență continuă pe parcursul colaborării noastre. Echipa noastră este mereu disponibilă pentru a răspunde la întrebări, a oferi sfaturi și a asigura că parteneriatele noastre sunt un succes.</a> 
                            </li>      
                        </ul> 
                        <div className="zona-description">
                             Cum ne puteți contacta:                        
                        </div>
                        <div className="zona-description">
                            Dacă sunteți interesat să colaborați cu noi sau aveți întrebări suplimentare, vă rugăm să ne contactați folosind formularul de mai jos sau prin intermediul datelor de contact disponibile pe site-ul nostru. Suntem încântați să discutăm mai în detaliu orice fel de neclaritate.                        
                        </div>
                        <div className="zona-description">
                            Cu drag,                        
                        </div>
                        <div className="zona-description">
                            Echipa Audiogram                        
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
 
export default ZonaProfesionisti;
