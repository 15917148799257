import React, { Component } from 'react'
import { Link } from 'react-router-dom';

class Act extends Component {
    render() { 
                
    return ( 
        <div>
            <section className="internal-page-wrapper">
                    <div className="container"> 
                        <div className="row justify-content-center text-center">
                                    <div className="col-12">
                                            <div className="title-wraper large">
                                                ACT - (Threshold de Contrast Auditiv)
                                            </div>
                                                <div className="zona-description">
                                                    Ce mai e nou in domeniul aparatelor auditive? 								
                                                </div>
                                                <div className="zona-description">
                                                    A fost lansat la sfârșitul anului 2023, la EUHA. Este un test unic efectuat în timpul consultației regulate, care ajută la diagnosticarea a cât de multă vorbire poate auzi/înțelege un pacient în zgomot.                                                
                                                </div> 
                                                <div className="zona-description">
                                                    Acest test obține un rezultat numeric pe o scară care ne permite să identificăm pragul de la care clientul încetează să înțeleagă vorbirea în situații zgomotoase.                                                
                                                </div>
                                                <div className="zona-description">
                                                    Care este avantajul real al acestui test? 
                                                    Ne permite să propunem clar, bazat pe un test metric și științific, cât de multă tehnologie de reducere a zgomotului este necesară în aparatul auditiv pentru ca utilizatorul să obțină un rezultat eficient conform nevoilor sale.                     
                                                </div>
                                                <div className="zona-description">
                                                    Chiar mai bine, începând cu cea mai recentă versiune a software-ului de reglaj 24.1, Hearsuite, Philips integrează automat rezultatul ACT în ajustările caracteristicilor de reducere a zgomotului (nu e disponibil în alte software-uri de ajustare ale altor producători).                                               
                                                </div> 
                                                <div className="zona-description">
                                                    Sunt absolut convins că integrarea ACT în procesul de diagnosticare (un test scurt de doar câteva minute) ne va permite să recomandăm cea mai bună tehnologie posibilă pacienților noștri, toate acestea bazate pe un diagnostic.                                              
                                                </div>   
                                                <div className="zona-description">
                                                    Tehnologia ACT a fost lansată în 24 Februarie, în cadrul “Audiogram you.niversity” și este disponibilă în toate centrele Audiogram.         
                                                </div> 
                                                <div className="zona-description">
                                                    Audiogram este distribuitorul exclusiv în România al aparatelor auditive PHILIPS, dar și al echipamentelor MedRX.         
                                                </div>
                                                <div className="zona-description">
                                                    Audiogram e cu și despre oameni, dar și despre inovație pură, la cel mai înalt nivel.         
                                                </div>
                                                <div className="zona-description">
                                                    Că de n-ar fi, nu s-ar auzi!
                                                </div>
                                                <div className="zona-description">
                                                    Pentru un test ACT complet, puteți face o programare aici: 
                                                </div>
                                            <h3 className="zona-description pret">Pret: 150 lei</h3>
                                        </div>
                                    </div>
                    </div>
            </section>
        </div>
        
        );     
    }
}
 
export default Act;
