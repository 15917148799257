import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Oracle extends Component {

    render() {
        return (
            <div>
                <section className="internal-page-wrapper">
                <div className="row justify-content-center text-center oracle-section">
                </div>
                    <div className="container">
                        <div className="title-wrapper large despre">
                                Oracle Chamber
                        </div>
                        <div className="zona-description">
                            Oracle Chamber: Templul Cunoașterii și Inovației în Domeniul Auzului
                        </div>
                        <div className="zona-description">
                            Oracle Chamber reprezintă un sanctuar digital al cunoașterii și inovației, dedicat specialiștilor din domeniul auzului care caută să împărtășească, să învețe și să creeze într-o comunitate exclusivistă. 
                        </div>
                        <div className="zona-description">
                            Inspirat de legendele uneia dintre camerele Hypogeum-ului din Malta, acest spațiu virtual este conceput pentru a emite „Sfântul Graal al Sunetului” pe 111 Hz, oferind acces la resurse și tehnologii de vârf într-un mediu restrâns și select.
                        </div>
                        <div className="zona-description">
                            Cum folosim Oracle Chamber:
                        </div>
                        <ul className="zona-clienti"> 
                            <li>
                                <a>Colaborare și Consultare Profesională:</a> 
                                    <li>
                                        <a>Specialiștii din domeniul ORL și audiologi pot să-și împărtășească cunoștințele și experiențele într-un mediu sigur și securizat.</a>
                                    </li>
                                    <li>
                                        <a>Posibilitatea de a organiza consultări online sau offline între specialiști, facilitând schimbul de idei și abordări.</a>
                                    </li>
                            </li>
                            <li>
                                <a>Închirierea Echipamentelor Audiologice:</a> 
                                    <li>
                                        <a>Oracle Chamber oferă posibilitatea specialiștilor să închirieze echipamente audiologice de ultimă generație pentru studii și cercetare, contribuind la avansarea practicii lor și la oferirea de servicii de calitate pacienților.</a>
                                    </li>
                            </li>        
                            <li>
                                <a>Evenimente și Educație Continuă prin Audiogram you.niversity</a>
                                    <li>
                                        <a>Organizarea de evenimente online sau offline, cum ar fi conferințe, seminarii și workshop-uri, pentru a promova educația continuă și schimbul de cunoștințe în domeniul auzului.</a>
                                    </li>
                            </li>      
                        </ul> 
                    </div>
                        
                </section>
            </div>
        );
    }
}

export default Oracle;
