import React, { Component } from 'react';

class DespreNoi extends Component {

    render() {
        return (
            <div>
                <section className="internal-page-wrapper">
                <div className="row justify-content-center text-center despre-section">
                </div>
                    <div className="container">
                        <div className="title-wrapper large despre">
                                Bine ați venit la Audiogram - Unde Sunetul Întâlnește Emoția!
                        </div>
                        <div className="zona-description">
                            La Audiogram, suntem mai mult decât o companie de aparate auditive. Suntem o comunitate plină de pasiune și entuziasm, dedicată îmbunătățirii vieților oamenilor prin sunet. Fondată cu dragoste și inovație, ne-am angajat să oferim o experiență auditivă extraordinară, cu un strop de magie în fiecare pas.
                        </div>
                        <div className="zona-description">
                            La inima fiecărei acțiuni, se află misiunea noastră de a aduce bucurie și conexiune în viața oamenilor prin sunet, cu ajutorul aparatelor auditive de la PHILIPS. Ne străduim să creăm experiențe auditive care să încânte, să inspire și să creeze legături emoționale puternice între oameni și lumea din jurul lor.
                        </div>
                        <div className="zona-description">
                            Valorile Noastre? Suntem Echipa Ta de Sunet.
                        </div>
                        <ul className="zona-clienti"> 
                            <li>
                                <a>Empatie și Îngrijire: Ne pasă profund de fiecare persoană care intră în contact cu noi, oferind empatie, suport și îngrijire în fiecare interacțiune.</a>   
                            </li>    
                            <li>
                                <a>Inovație și Excelență: Suntem dedicați permanent inovației și excelenței în tot ceea ce facem, aducând mereu cele mai bune soluții și tehnologii pentru clienții noștri.</a>  
                            </li>    
                            <li>
                                <a>Comunitate și Conexiune: Ne străduim să construim o comunitate puternică și conectată, unde fiecare membru se simte acceptat, apreciat și încurajat să își împărtășească povestea și să se îmbunătățească împreună.</a> 
                            </li>    
                        </ul> 
                        <div className="zona-description">
                            Indiferent de unde te afli în călătoria ta auditivă, suntem aici pentru tine. De la primul zâmbet când auzi sunetul clar până la fiecare moment special în care îți simți inima cântând, suntem alături de tine oferindu-ți sprijin, înțelegere și cele mai bune soluții pentru nevoile tale auditive.                        
                        </div>
                        <div className="zona-description">
                            Bine ai venit în lumea caldă și jucausă a Audiogram, unde sunetul devine poveste și emoție!                        
                        </div>
                    </div>
                        
                </section>
            </div>
        );
    }
}

export default DespreNoi;
