import React, { Component } from 'react'
import { Link } from 'react-router-dom';

class Tinnometrie extends Component {
    render() { 
                
    return ( 
        <div>
            <section className="internal-page-wrapper">
                    <div className="container"> 
                        <div className="row justify-content-center text-center">
                                    <div className="col-12">
                                            <div className="title-wraper large">
                                                Tinnometria nou nouță 
                                            </div>
                                                <div className="zona-description">
                                                    Tinograma, este un test care poate ajuta la identificarea exactă a frecvenței și intensității sunetului perceput de pacient. Acest test este util în diagnosticarea și evaluarea tinnitusului, o afecțiune caracterizată prin auzirea sunetelor în urechi sau în cap în absența unor surse externe de sunet. 								
                                                </div>
                                                <div className="zona-description">
                                                    În timpul tinogramei pentru tinnitus, pacientul ascultă diferite frecvențe și intensități ale sunetului și este rugat să raporteze frecvența și intensitatea sunetului tinnitusului său în comparație cu aceste sunete de test. Aparatul de tinogramă înregistrează aceste răspunsuri și ajută la identificarea exactă a caracteristicilor tinnitusului.                                                
                                                </div> 
                                                <div className="zona-description">
                                                Rezultatele tinogramei pot oferi informații importante despre tinnitus, inclusiv:                                                
                                                </div>
                                                <ul className="zona-clienti"> 
                                                <li>
                                                    <a>Frecvența tinnitusului: Tinograma poate ajuta la determinarea frecvenței predominante a sunetului perceput de pacient în timpul tinnitusului. Acest lucru este util în planificarea tratamentului și gestionarea tinnitusului.</a>   
                                                </li>    
                                                <li>
                                                    <a>Intensitatea tinnitusului: Testul poate măsura și evalua intensitatea tinnitusului, adică nivelul de disconfort sau deranjament cauzat de sunetul perceput de pacient.</a>  
                                                </li>    
                                                <li>
                                                    <a>Caracteristicile tinnitusului: În unele cazuri, tinograma poate oferi informații suplimentare despre caracteristicile tinnitusului, cum ar fi modul în care sunetul variază în timp sau dacă este asociat cu alte simptome sau afecțiuni.</a> 
                                                </li>        
                                            </ul> 
                                                <div className="zona-description">
                                                    Tinograma pentru tinnitus poate fi utilă în diagnosticarea și gestionarea acestei afecțiuni, oferind informații precise și detaliate despre sunetul perceput de pacient. Este adesea utilizată în combinație cu alte teste și evaluări pentru a obține o imagine completă a tinnitusului și pentru a dezvolta un plan de tratament personalizat pentru pacient.                                                
                                                </div>
                                                <div className="zona-description">
                                                    Pentru o Tinogramă  completă si precisă, puteți face o programare aici:                                               
                                                </div>     
                                            <h3 className="zona-description pret">Pret: 100 lei</h3>
                                        </div>
                                    </div>
                    </div>
            </section>
        </div>
        
        );     
    }
}
 
export default Tinnometrie;
